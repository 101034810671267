import Webcam from "react-webcam";
import {useCallback, useRef, useState} from "react";
import {BrowserView, MobileView} from "react-device-detect";
import Table from 'react-bootstrap/Table'
import axios from "axios";

const CustomWebcam = () => {

    const [uploadMessage, setUploadMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const mobileVideoConstraints = {
        width: 320,
        height: 320,
        facingMode: {exact: "environment"}
    };

    const desktopVideoConstraints = {
        width: 640,
        height: 480
    }

    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
    }, [webcamRef]);
    const retake = () => {
        setImgSrc(null);
    };
    const upload = async () => {
        axios.post('/api/upload/image', imgSrc, {
            headers: {
                "Content-Type": "image/jpeg;base64",
                "Authorization": localStorage.getItem("jwt-token")
            }
        }).then(res => {
            console.log(res);
            if (res.data['status'] === "OK") {
                console.log("upload done");
                setUploadMessage("Upload complete for: "+res.message);
                setAlertType("alert alert-success")
            } else {
                console.log("upload failed");
                setUploadMessage("Upload failed: "+res.data['message']);
                setAlertType("alert alert-warning")
            }
        }).catch(error => {
            console.log(error);
            if (error.response) {
                setUploadMessage(JSON.stringify(error.response.data));
            } else if (error.request) {
                setUploadMessage(JSON.stringify(error.request));
            } else {
                setUploadMessage(JSON.stringify(error.message));
            }
            setAlertType("alert alert-danger");
            console.log(error.config);
        });
    };

    return (
        <>
            <div className="text-center">
                <Table borderless>
                <tbody>
                    <tr>
                        <td>
                            <MobileView>
                                {imgSrc ? (
                                    <img src={imgSrc} alt="webcam"/>
                                ) : (
                                    <Webcam videoConstraints={mobileVideoConstraints} ref={webcamRef} screenshotFormat="image/jpeg" screenshotQuality={0.9} />
                                )}
                            </MobileView>
                            <BrowserView>
                                {imgSrc ? (
                                    <img src={imgSrc} alt="webcam"/>
                                ) : (
                                    <Webcam videoConstraints={desktopVideoConstraints} ref={webcamRef} screenshotFormat="image/jpeg" screenshotQuality={0.9} />
                                )}
                            </BrowserView>
                        </td>
                    </tr>
                    <div className="btn-container">
                        {imgSrc ? (
                            <div>
                                <tr>
                                    <td>
                                        <button className="btn btn-lg btn-primary btn-block" onClick={upload}>Upload
                                            Photo
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button className="btn btn-lg btn-primary btn-block" onClick={retake}>Retake
                                            Photo
                                        </button>
                                    </td>
                                </tr>
                            </div>
                        ) : (
                            <tr>
                                <td>
                                    <button className="btn btn-lg btn-primary btn-block" onClick={capture}>Capture
                                        Photo
                                    </button>
                                </td>
                            </tr>
                        )}
                    </div>
                    </tbody>

                </Table>
                {uploadMessage && <div className={alertType} role="alert">{uploadMessage}</div>}
            </div>
        </>
    );
};

export default CustomWebcam;