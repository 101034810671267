import CustomWebcam from "./CustomWebcam";
import {Container} from "react-bootstrap";

const ScanBarcodes = () => {
    return (
        <>
            <Container className="d-flex align-items-center justify-content-center">
                <CustomWebcam />
            </Container>
        </>
    );
}

export default ScanBarcodes;