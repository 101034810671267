import {useState} from "react";
import axios from "axios";
import {Navigate, useLocation} from "react-router-dom";
import {useAuth} from "./AuthContext";
import './Login.css';
import queryString from "qs";

const Login = () => {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();
    const [authenticated, setAuthenticated] = useState(localStorage.getItem('authenticated') === 'true');
    const { auth, setAuth, admin, setAdmin } = useAuth();

    const performLogin = async (e) => {
        const querystring = require('querystring-es3');
        axios.post('/api/user/login',
            querystring.stringify({
                username: username,
                password: password
            }), {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }).then(response => {
            localStorage.setItem('user-name', response.data['username']);
            localStorage.setItem('jwt-token', response.data['token']);
            localStorage.setItem('roles', response.data['authorities']);
            localStorage.setItem('authenticated', true);
            setAuth(true);
            if (response.data['authorities'].includes('ROLE_ADMIN')) {
                localStorage.setItem('admin', true);
                setAdmin(true);
            } else {
                localStorage.setItem('admin', false);
                setAdmin(false);
            }
            console.log(response);
            setAuthenticated(true);
            console.log("Auth: "+(auth ? "yes" : "no")+", Admin: "+(admin ? "yes" : "no"))
        }).catch(error => {
            console.error(error);
            setError('Invalid user name or password');
            setUserName("");
            setPassword("");
        })
        e.preventDefault();
    }

    const location = useLocation();
    if (authenticated) {
        const {redirectTo} = queryString.parse(location.search);
        const newLocation = redirectTo == null ? "/" : redirectTo;
        return <Navigate replace to={newLocation}/>
    } else {
        return (
            <div className="text-center">
                <form className="form-signin w-100 m-auto" onSubmit={performLogin}>
                    <img className="mb-4" src="remgant-logo-75x75.png" alt=""
                         width="72"
                         height="72"/>
                    <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
                    <label htmlFor="inputEmail" className="sr-only">Email address</label>
                    <input type="text" id="inputEmail" className="form-control"
                           onChange={e => setUserName(e.target.value)}
                           name="email" placeholder="Email address" required autoFocus/>
                    <label htmlFor="inputPassword" className="sr-only">Password</label>
                    <input type="password" id="inputPassword" className="form-control"
                           onChange={e => setPassword(e.target.value)}
                           name="password" placeholder="Password" required/>
                    <div className="checkbox mb-3">
                        <input type="checkbox" value="remember-me"/>
                        <label>Remember me</label>
                    </div>
                    <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
                </form>
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
            </div>
        );
    }

}

export default Login;
