import {Navigate} from "react-router-dom";
import {useAuth} from "./AuthContext";
import {useEffect} from "react";
import axios from "axios";

const Logout = () => {

    const { setAuth, setAdmin, setCurator, setCreator } = useAuth();

    useEffect(() => {
        axios.get("/api/user/logout")
            .then((response) => {
                localStorage.removeItem('user-name');
                localStorage.removeItem('jwt-token');
                localStorage.removeItem('roles');
                localStorage.setItem('authenticated', false);
                setAuth(false);
                setAdmin(false);
                setCurator(false);
                setCreator(false);
            })
            .catch(error => console.error(error))
    }, [setAuth, setAdmin, setCurator, setCreator]);

    return <Navigate replace to={"/"}/>
}

export default Logout;