import {useState} from "react";
import axios from "axios";

const UploadImage = () => {

    const [uploadMessage, setUploadMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const performUpload = async (e) => {
        const fileInput = document.querySelector("#contentFile");
        const formData = new FormData();

        e.preventDefault();

        formData.append("file", fileInput.files[0]);

        setUploadMessage("Uploading...");
        setAlertType("alert alert-warning")

        axios.post("/api/upload/image", formData,
            {
                headers: {
                    "Authorization": localStorage.getItem("jwt-token")
                }
            })
            .then(res => {
                console.log(res);
                if (res.status !== 200) {
                    console.log('upload failed');
                    setUploadMessage(JSON.stringify(res.data));
                    setAlertType("alert alert-danger");
                } else if (res.data['status'] === "OK") {
                    console.log("upload done");
                    setUploadMessage("Upload complete");
                    setAlertType("alert alert-success")
                } else {
                    console.log("upload failed");
                    setUploadMessage("Upload failed: "+res.data['message']);
                    setAlertType("alert alert-warning")
                }
            })
            .catch(error => {
                if (error.response) {
                    setUploadMessage(JSON.stringify(error.response.data));
                } else if (error.request) {
                    setUploadMessage(JSON.stringify(error.request));
                } else {
                    setUploadMessage(JSON.stringify(error.message));
                }
                setAlertType("alert alert-danger");
                console.log(error.config);
            });
    }
    return (
        <div className="text-center">
            <form className="form-signin w-100 m-auto" onSubmit={performUpload}>
                <h1 className="h3 mb-3 font-weight-normal">Upload Image</h1>
                <div className="row g-3">

                    <div className="col-12">
                        <label htmlFor="file" className="form-label">File</label>
                        <input type="file" className="form-control" id="contentFile"
                               required/>
                    </div>

                </div>
                <hr className="my-4"/>
                <button className="btn btn-lg btn-primary btn-block" type="submit">Upload</button>
            </form>
            {uploadMessage && <div className={alertType} role="alert">{uploadMessage}</div>}
        </div>);
}

export default UploadImage;